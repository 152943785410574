import { ErrorHandler, inject, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { isPlatformBrowser } from '@angular/common';


const RELOAD_TIMEOUT = 60 * 1000; // 1 min

export class CustomErrorHandler implements ErrorHandler {
    platformId = inject(PLATFORM_ID);
    constructor( ) { }

    handleError(error: any): void {
        if (isPlatformBrowser(this.platformId)) {
          // https://sentry.io/answers/failed-to-fetch-dynamically-imported-module-in-react/
          const lastReload = localStorage.getItem('lastReload');
          const now = Date.now();
          const messagesToReload = [
            'Failed to fetch dynamically imported module',
            '\'text/html\' is not a valid JavaScript MIME type',
            'Importing a module script failed'
          ];

          // Forced reload page to get relevant js modules
          if ((!lastReload || (now - parseInt(lastReload, 10)) > RELOAD_TIMEOUT) &&
              error.message && messagesToReload.some((message) => error.message.includes(message))) {
            localStorage.setItem('lastReload', now.toString());
            Sentry.captureException(`Notification: Page was reloaded to get updates >>> , ${error.originalError || error}`);
            window.location.reload();
            throw error;
          }
        }

        Sentry.captureException(error.originalError || error);

        throw error;
    }
}

export default CustomErrorHandler;
